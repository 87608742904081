<template>
  <v-app>
    <v-card flat width="100%" height="100%" color="#f1e6ee" class="lot_login">
      <v-card flat width="480" class="login-wrap">
        <v-card-title class="pa-0 text-center">
          <h1>
<!--            <p class="font-italic font-weight-light blue-grey&#45;&#45;text text-sm-h6">방산</p>-->
            블록체인 기반<br>
            <strong>사내 추첨 시스템</strong>
          </h1>
          <div class="pt-4 d-block h-desc">로그인하시기 바랍니다.</div>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container class="pa-0 align-content-center align-center">
            <v-row class="mt-0 justify-center">
              <v-col class="form-area rounded">
                <validation-observer v-slot="{ invalid }">
                  <form @submit.prevent="submit">
                    <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="ID"
                        :custom-messages="{ required: '사번을 입력하세요.' }"
                        class="relative"
                    >
                      <v-text-field
                          :error-messages="errors"
                          v-model="id"
                          label="사번"
                          :rules="[rules.required, rules.min]"
                          autofocus solo flat outlined
                          height="50"
                          class="mt-0 pb-0 pt-0 rounded-sm f14"
                          v-on:keyup.enter="login()"
                      ></v-text-field>
                    </validation-provider>

                    <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Password"
                        :custom-messages="{ required: '비밀번호를 입력하세요.' }"
                        class="relative"
                    >
                      <v-text-field
                          :error-messages="errors"
                          v-model="password"
                          type="password"
                          label="비밀번호"
                          solo flat outlined
                          height="50"
                          class="mt-0 pb-0 pt-0 rounded-sm f14"
                          v-on:keyup.enter="login()"
                      ></v-text-field>
<!--                      <v-text-field>초기 비밀번호는 사번입니다.</v-text-field>-->
                    </validation-provider>
                    <v-bottom-sheet v></v-bottom-sheet>
                    <v-btn
                        block depressed
                        :disabled="invalid || loading"
                        :loading="loading"
                        height="50"
                        color="primary"
                        class="mt-1 rounded-sm white--text"
                        @click="login">로그인
                    </v-btn>
                    <v-btn
                        block depressed
                        :loading="loadingResetPassword"
                        height="50"
                        color="primary"
                        class="mt-1 rounded-sm white--text"
                        @click="resetPassword">비밀번호 초기화
                    </v-btn>
                    <!-- @click="go('/main')" -->
                  </form>
                </validation-observer>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <div class="bg-area">
        <div class="symbol_01">
          <v-img :src="require('@/assets/images/ess_bg01.svg')"></v-img>
        </div>
        <div class="symbol_02">
          <v-img :src="require('@/assets/images/ess_bg02.svg')"></v-img>
        </div>
      </div>
    </v-card>
  </v-app>
</template>

<script>
// import { api } from '@/axios';
import CryptoJS from 'crypto-js';
import storage from '../common/storage';

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      id: '',
      password: '',
      loading: false,
      loadingResetPassword: false,
      rules: {
        required: (value) => !!value || '필수 항목입니다.',
        min: (v) => v.length >= 8 || '적어도 8글자 이상이어야 합니다.',
      },
    };
  },
  created() {
    this.$EventBus.$on('logout', this.onReceiveLogout);
    // 토큰 오류 경고창을 중첩하지 않도록
    this.$store.commit('setExpireDialog', false);
    const { accessToken } = storage.getToken();
    // console.log(accessToken);
    if (accessToken) {
      if (this.$route.query.eventId) {
        this.$router.push({ name: 'main', params: { eventId: this.$route.query.eventId } });
      } else {
        this.$router.push({
          path: '/web/main',
        });
      }
    }
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        const result = await this.$axios.post('/auth/authenticate-user-info', {
          userId: this.id,
          password: CryptoJS.SHA256(this.password).toString(),
        });
        console.log(result);
        if (result.status === 200) {
          if (result.data === false) {
            await this.$router.push({
              path: '/web/changePw',
              query: { userId: this.id },
            });
          } else if (result.data === true) {
            try {
              const loginResult = await this.$axios.post('/auth/authenticate-user', {
                userId: this.id,
                password: CryptoJS.SHA256(this.password).toString(),
              });
              if (loginResult.status === 200) {
                this.loginSuccess = true;
                storage.setToken(loginResult.data);
                storage.setRefreshToken(loginResult.data);
                storage.setItem('userId', this.id);
                localStorage.setItem('userId', this.id);
                if (this.$route.query.eventId) {
                  this.$router.push({ name: 'main', params: { eventId: this.$route.query.eventId } });
                } else {
                  await this.$router.push({
                    path: '/web/main',
                  });
                }
              }
            } catch (err) {
              await this.$dialog.warning({
                text: '로그인 정보가 올바르지 않습니다',
                title: '로그인 실패',
              });
            }
          }
        }
      } catch (err) {
        await this.$dialog.warning({
          text: '로그인 정보가 올바르지 않습니다',
          title: '로그인 실패',
        });
        this.loginError = true;
        throw new Error(err);
      } finally {
        this.loading = false;
      }
    },
    async resetPassword() {
      await this.$router.push({
        path: '/web/resetPw',
      });
    },

    go(path) {
      console.log(this.$route.path);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    submit() {
      console.log('submit');
    },
    onReceiveLogout() {
      storage.removeToken();
      storage.removeRefreshToken();
    },
  },
};
</script>
